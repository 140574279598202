<template>
  <div class="button-grid-wrapper">
    <div class="button-grid" ref="grid">
      <template v-for="option in options" :key="option">

        <base-button :active-variant="variant" :variant="inactiveVariant" :class="getBtnClass" tag="label" :active="isActive(option)" :disabled="disabled">
          <span class="label-with-icon">
            <!-- icon -->
            <i v-if="option.icon" :class="getIconClasses(option)" :style="option.iconStyle"></i>
            <!-- label text -->
            <span :class="option.labelClass" :style="option.labelStyle" v-html="option.label"></span>
          </span>
          <input
            :type="type"
            :value="option.value"
            :class="option.inputClass"
            v-show="showInput"
            :name="name"
            :id="getOptionId(option)"
            :checked="isActive(option)"
            @change="toggleOption(option)"/>
        </base-button>

      </template>
    </div>
  </div>
</template>

<script>
  import { kebabCase, pull, toNumber } from 'lodash';
  import ClassList from '@/helpers/ClassList';
  import BaseButton from "./BaseButton.vue";

  export default {
    components: {
      BaseButton
    },
    props: {

      /**
       * primary props
       */
      name: { type: String, required: true },
      value: { type: [String, Number, Array] }, // MUST be array when type == 'checkbox'
      options: { type: Array, required: true },
      // input type and component behaviour
      type: {
        type: String,
        default: 'checkbox',
        validator(value) {
          return ['checkbox', 'radio'].includes(value);
        },
      },
      disabled: {
        type: Boolean,
        default: false
      },

      /**
       * variant props
       */
      variant: { type: String, default: 'info' },
      // if defined, this will be the inactive variant and 'variant' prop will be the active variant
      // by default this will be outline-[variant]
      inactiveVariant: {
        type: String,
        default: (props) => {
          return 'minimal-'+props.variant;
        }
      },

      /**
       * styling options
       */
      // flex direction for button content
      btnDirection: {
        type: String,
        default: 'row',
        validator(value) {
          return ['column', 'row'].includes(value);
        }
      },
      showInput: { type: Boolean, default: true }, // show/hide input (checkbox)
      minColWidth: { type: Number, default: 150 },
      maxCols: { type: Number, default: null }, // maximum number of columns allowed in the grid
    },
    data() {
      return {
        maxPossibleCols: 0
      }
    },
    computed: {
      getBtnClass() {
        return ['grid-btn', 'direction-' + this.btnDirection];
      },
      getValue() {
        if (this.type == 'checkbox' && !Array.isArray(this.value)) {
          return this.value ? [this.value] : [];
        }
        return this.value;
      },
      gridCols() {
        let baseRows = Math.ceil(this.options.length / this.maxPossibleCols);
        let cols = this.options.length / baseRows;
        return this.maxCols?  Math.min(cols, this.maxCols) : cols;
      },
      gridRows() {
        return Math.ceil(this.options.length / this.gridCols);
      }
    },
    methods: {
      /*
       * mark up methods
       */
      getOptionId(option) {
        return this.name + '_' + kebabCase(option.value)
      },
      getIconClasses(option) {
        return ClassList('bi')
        .addClass(option.icon)
        .addClass(option.iconClass)
        .getAll()
      },

      /*
       * value logic methods
       */
      isActive(option) {
        if (Array.isArray(this.getValue)) {
          return this.getValue.includes(option.value);
        } else {
          return this.value == option.value;
        }
      },
      updateValue(value) {
        this.$emit('update:value', value);
      },
      toggleOption(option) {
        // if radio, replace value
        if (this.type == 'radio') {
          this.updateValue(option.value);
          return; // then exit
        }
        // else (checkbox), toggle between select and deselect
        this.isActive(option) ? this.deselectOption(option) : this.selectOption(option);
      },
      selectOption(option) {
        let newVal = this.getValue;
        if (option.exclusive ?? false) { 
          newVal = [option.value];
        } else {
          newVal.push(option.value);
        }
        this.updateValue(newVal);
      },
      deselectOption(option) {
        this.updateValue(pull(this.getValue, option.value));
      },

      /*
       * responsive features
       */
      setGridWidth() {
        this.maxPossibleCols = Math.floor(toNumber(this.$refs.grid.offsetWidth) / toNumber(this.minColWidth));
      }
    },
    mounted() {
      this.setGridWidth();
      window.addEventListener("resize", this.setGridWidth);
    },
    unmounted() {
      window.removeEventListener("resize", this.setGridWidth);
    }
  }
</script>

<style lang="scss">

  .button-grid-wrapper {
    display: flex;
    align-items: stretch;
    background: rgba($white, 0.4);
    border-radius: $border-radius;
    padding: 5px;
    width: 100%;

    .button-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(v-bind(gridCols), 1fr);
      grid-template-rows: repeat(v-bind(gridRows), 1fr);
      grid-gap: map-get($spacers, 2);
    }

    .btn.grid-btn {
      display: flex;
      flex: 1;
      border: none !important;
      &:hover {
        opacity: 0.8;
      }

      // remove letter spacing on btn-minimal
      @each $color, $value in $theme-colors {
        &.btn-minimal-#{$color} {
          background-color: rgba($gray-100, 0.7);
          letter-spacing: 0;
        }
        &.btn-minimal-filled-#{$color} {
          letter-spacing: 0;
        }
      }

      .label-with-icon {
        display: flex;
        align-items: center;
      }

      input {
        cursor: pointer;
        opacity: 0.8;
      }

      i {
        opacity: 0.5;
      }
      i::before {
        font-size: inherit;
      }

      // vertical button variant
      &.direction-column {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .label-with-icon {
          flex-direction: column;
          justify-content: center;
          flex: 1;
        }
        input {
          margin-top: map-get($spacers, 2);
        }
        i {
          margin-top: map-get($spacers, 2);
          margin-bottom: map-get($spacers, 2);
          width: 40px;
          font-size: 40px;
        }
      }

      // horizontal button variant
      &.direction-row {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        .label-with-icon {
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          > span {
            text-align: left;
          }
        }
        input {
          margin-right: map-get($spacers, 2);
        }
        i {
          margin-right: map-get($spacers, 3);
          width: 30px;
          font-size: 30px;
        }
      }
    }
  }
</style>